// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-risers-index-js": () => import("./../../../src/pages/about-risers/index.js" /* webpackChunkName: "component---src-pages-about-risers-index-js" */),
  "component---src-pages-about-the-rise-prize-index-js": () => import("./../../../src/pages/about-the-rise-prize/index.js" /* webpackChunkName: "component---src-pages-about-the-rise-prize-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insights-index-js": () => import("./../../../src/pages/insights/index.js" /* webpackChunkName: "component---src-pages-insights-index-js" */),
  "component---src-pages-our-partners-index-js": () => import("./../../../src/pages/our-partners/index.js" /* webpackChunkName: "component---src-pages-our-partners-index-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../../../src/pages/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-stories-index-js": () => import("./../../../src/pages/stories/index.js" /* webpackChunkName: "component---src-pages-stories-index-js" */),
  "component---src-pages-terms-of-use-index-js": () => import("./../../../src/pages/terms-of-use/index.js" /* webpackChunkName: "component---src-pages-terms-of-use-index-js" */),
  "component---src-pages-winners-index-js": () => import("./../../../src/pages/winners/index.js" /* webpackChunkName: "component---src-pages-winners-index-js" */),
  "component---src-templates-story-js": () => import("./../../../src/templates/story.js" /* webpackChunkName: "component---src-templates-story-js" */)
}

